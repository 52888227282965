<template>
    <div >
        <v-card flat class="mb-7">
            <v-card-text class="pa-0" ref="activityWindow">
                <v-tabs v-model="tab" color="info" @change="setHeight(height)">
                    <v-tab key="courses" class="text-capitalize font-weight-light subtitle-1">
                        <v-icon class="d-block d-md-none">mdi-access-point</v-icon>
                        <span class="d-none d-md-block">Курсы</span>
                    </v-tab>
                    <v-tab key="activity" class="text-capitalize font-weight-light subtitle-1">
                        <v-icon class="d-block d-md-none">mdi-account-circle</v-icon>
                        <span class="d-none d-md-block">Активность</span>
                    </v-tab>
                    <v-tab key="news" class="text-capitalize font-weight-light subtitle-1">
                        <v-icon class="d-block d-md-none">mdi-news</v-icon>
                        <span class="d-none d-md-block">Новости</span>
                    </v-tab>
                    <v-tab key="settings" class="text-capitalize font-weight-light subtitle-1">
                        <v-icon class="d-block d-md-none">mdi-cog</v-icon>
                        <span class="d-none d-md-block">Настройки</span>
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                    <!-- Activity Tabs -->
                    <v-tab-item key="courses">
                        <v-card flat>
                            <v-card-text ref="activity">
                                <div  class="vs-scrollable" v-bar ref="scrollWindow1">
                                    <courses :userId="userId"/>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item key="activity">
                        <v-card flat>
                            <v-card-text>
                                <div  class="vs-scrollable" v-bar ref="scrollWindow2">
                                    <activity/>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                                        <v-tab-item key="news">
                        <v-card flat>
                            <v-card-text>
                                <div  class="vs-scrollable" v-bar ref="scrollWindow4">
                                    <TheRecentNews/>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item key="settings">
                        <v-card flat>
                            <v-card-text>
                                <div  class="vs-scrollable" v-bar ref="scrollWindow3">
                                <settings :userId="userId"/>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
    import activity from './activity'
    import courses from './courses'
    import settings from './settings'
    import TheRecentNews from '../main/TheRecentNews'
    export default {
        name: "TheActivityTimeline",
        data: () => ({
                tab: null,
                height: '100px'
            }),
            props:{
              userId:Number  
            },
        watch: {
            tab() {
                this.$nextTick(() => {
                    this.setHeight(this.height)
                })

            }
        },
        methods: {
            setHeight(val) {
                console.log(val)
                if(val!=0){
                    this.height = val
                }
                if (this.$refs.scrollWindow1) {
                    this.$refs.scrollWindow1.style.height = this.height
                }
                if (this.$refs.scrollWindow2) {
                    this.$refs.scrollWindow2.style.height = this.height
                }
                if (this.$refs.scrollWindow3) {
                    this.$refs.scrollWindow3.style.height = this.height
                }
                                if (this.$refs.scrollWindow4) {
                    this.$refs.scrollWindow4.style.height = this.height
                }
            }
        },
        components: {
            TheRecentNews,
            activity,
            courses,
            settings
        }
    };
</script>
<template>
    <div>
        <v-list three-line>
            <template v-for="course in courses" >

                <v-list-item  :key="course.courseId" class="px-1">
                    <v-list-item-icon>
                        <v-icon size="56" color='primary'>mdi-book-open-variant</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{course.course.name}}
                        </v-list-item-title>
                        <v-list-item-subtitle> 
                            <v-btn color='info' dark class='my-3' :to='"/lesson/"+course.lastLesson.id'>Самоподготовка<v-icon class='pl-1'>mdi-alert-circle</v-icon></v-btn>
                        </v-list-item-subtitle>
                        <v-row>
                            <v-col align="center"  align-self="center">
                                <v-btn color='primary'  outlined @click="groupCourseId=course.id;statModal=true; $refs.uprogress.fetchData()">Статистика<v-icon class='pl-1'>mdi-chart-bar-stacked</v-icon></v-btn>
                            </v-col>
                            <v-col align="center" align-self="center">
                                Средняя успеваемость<v-rating
                                    readonly
                                    :value="course.rating"
                                    background-color="purple lighten-3"
                                    color="purple"
                                    dense
                                    half-increments
                                    size="20"
                                    ></v-rating>
                            </v-col>
                            <v-col align="center" align-self="center">
                                Уроков пройдено
                                <v-progress-linear class="my-1"
                                                   :value="100*course.lessonsDone/course.lessonsCount"
                                                   color="blue"
                                                   height="25"
                                                   >
                                    <template>
                                        <strong>{{course.lessonsDone}}/{{course.lessonsCount}}</strong>
                                    </template>
                                </v-progress-linear>
                            </v-col>
                        </v-row>


                    </v-list-item-content>
                    <v-list-item-action>
                        <v-btn icon :to="'/courses/'+course.id" >
                            <v-icon size="48" color='primary'>mdi-arrow-right-circle</v-icon>
                        </v-btn>

                    </v-list-item-action>
                    <hr/>

                </v-list-item>
                <v-divider :key="'d'+course.courseId"

                           ></v-divider>
            </template>
        </v-list>
        <div>
            <v-row style="height:100%" v-if='courses.length==0'>
                <v-col align='center'  self-align='center' class="pa-12">
                    
                    <h1>Нет добавленных курсов</h1>
                    <v-icon size="128">mdi-book-open-page-variant</v-icon>
                </v-col>
            </v-row>
        </div>
            <v-dialog
      v-model="statModal"
      max-width="1024"
    >
                <v-card scrollable>
                    <v-card-text>
                        <uprogress ref="uprogress" :groupCourseId="groupCourseId" :userId="userId"/>
                    </v-card-text>
                </v-card>
            </v-dialog>
    </div>
</template>

<script>
    import uprogress from './progress'

    export default {
        name: "index",
        data: () => ({
                courses: [],
                loading: true,
                statModal: false,
                lessonsDone: 10,
                groupCourseId:0,
            }),
            props:{
                userId:Number
            },
        methods: {
            fetchData() {
                this.$http.get('courses/my-courses?id=' + this.userId).then(response => {
                    this.courses = response.data
                    this.loading = false
                })

            }
        },
        mounted() {
            this.fetchData()
        },
        components: {
            uprogress
        }
    };
</script>
<template>
  <v-card class="pa-3 mt-3">
    <v-card-text class="pa-1">
      <h3 class="title font-weight-regular">Последние новости</h3>
    </v-card-text>
    <v-card-text class="pa-0">
      <div class="d-md-flex align-items-center">
        <div v-bar class="vs-scrollable w-100">
          <div>
            <div
              class="comment-widgets position-relative"
              v-for="comment in comments"
              :comment="comment"
              :key="comment.commenttitle"
            >
              <!-- Comment Row -->
              <div class="d-flex flex-row comment-row mt-0 pa-3" :class="[comment.activeClass]">
                <div class="pa-2">
                  <img
                    :src="comment.user.avatar"
                    width="90"
                    class="rounded-circle"
                  />
                </div>
                <div class="comment-text w-100 pa-3">
                  <h3 class="font-weight-regular">{{ comment.user.last_name }} {{ comment.user.name }} - {{ comment.title }}</h3>
                  <span class="d-block my-2 subtitle-2 font-weight-regular">{{ comment.text }}</span>
                  <div class="comment-footer">
                    <span class="text-muted mr-2">Добавлено {{ parseInt(comment.date) | moment("from", "now") }}</span>
                    <v-chip class="ma-2 error" small label v-if="!comment.level">Важно</v-chip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "TheRecentComments",
  props: {
    comment: Object
  },
  data: () => ({
      loading:true,
    comments: []
  }),
          methods: {
            fetchData() {
                this.$http.get('news/get').then(response => {
                    this.comments = response.data
                                      this.$nextTick(() => {
                    this.$parent.matchHeight()
                })
                    this.loading = false
                })

            }
        },
        mounted() {
            this.fetchData()
        },
};
</script>
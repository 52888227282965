<template>
    <!-- ----------------------------------------------------------------------------- -->
    <!-- Events -->
    <!-- ----------------------------------------------------------------------------- -->
    <div>
        <v-overlay
          :absolute="true"
          :value="loading"
        ><v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
        </v-overlay>
            <v-sheet       
                height="54"
                >
                <v-toolbar
                    flat
                    >
                    <v-btn
                        outlined
                        class="mr-4"
                        color="grey darken-2"
                        @click="setToday"
                        >
                        Сегодня
                    </v-btn>
                    <v-btn
                        fab
                        text
                        small
                        color="grey darken-2"
                        @click="$refs.calendar.prev()"
                        >
                        <v-icon small>
                            mdi-chevron-left
                        </v-icon>
                    </v-btn>
                    <v-btn
                        fab
                        text
                        small
                        color="grey darken-2"
                        @click="$refs.calendar.next()"
                        >
                        <v-icon small>
                            mdi-chevron-right
                        </v-icon>
                    </v-btn>

                    <v-menu
                        bottom
                        right
                        >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                outlined
                                color="grey darken-2"
                                v-bind="attrs"
                                v-on="on"
                                >
                                <span>{{ typeToLabel[type] }}</span>
                                <v-icon right>
                                    mdi-menu-down
                                </v-icon>
                            </v-btn>
                        </template>
                        <v-list>


                            <v-list-item @click="type = 'month'">
                                <v-list-item-title>Месяц</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="type = 'week'">
                                <v-list-item-title>Неделя</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="type = '4day'">
                                <v-list-item-title>4 дня</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="type = 'day'">
                                <v-list-item-title>День</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-toolbar>

            </v-sheet>
            <v-sheet height="600">
                <v-calendar
                    v-if="!loading"
                    ref="calendar"
                    v-model="focus"
                    color="primary"
                    :type="type"
                    :events="events"
                    :event-ripple="false"
                    locale="ru-RU"
                    :weekdays="[1, 2, 3, 4, 5, 6, 0]"
                    @click:event="showEvent"
                    @click:more="viewDay"
                    @click:date="viewDay"
                    >
                    <template v-slot:event="{ event }">
                        <div
                            
                            >{{ parseInt(event.start) | moment("HH:mm") }} - {{event.name}}</div>
                    </template>
                </v-calendar>
                <v-menu
                    v-model="selectedOpen"
                    :close-on-content-click="false"
                    :activator="selectedElement"
                    offset-x
                    >
                    <v-card
                        color="grey lighten-4"
                        min-width="200px"
                        flat
                        >
                        <v-toolbar
                            :color="selectedEvent.color"
                            dark
                            >
                            <v-toolbar-title>Занятие</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn
                                small
                                fab
                                dark
                                color="red"
                                @click="selectedOpen = false"
                                >
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-card-text>
                            <span v-html="selectedEvent.name"></span><br>
                            {{ parseInt(selectedEvent.start) | moment("HH:mm") }} - {{ parseInt(selectedEvent.end) | moment("HH:mm") }}
                                                                          <v-chip small  v-if="selectedEvent.type=='theory'">Теоретическое занятие</v-chip>
                                            <v-chip small  color="primary"  v-if="selectedEvent.type=='practice'">Практическое занятие</v-chip>
                                            <v-chip small color="warning"  v-if="selectedEvent.type=='self'">Самостоятельная подготовка</v-chip>
                                            <v-chip  small  color="error"  v-if="selectedEvent.type=='control'">Контроль</v-chip>
                        </v-card-text>
                        <v-card-actions>
                            
                        </v-card-actions>
                    </v-card>
                </v-menu>
            </v-sheet>
    </div>
</template>
<style>
    .v-calendar-weekly__head{
        padding-top:10px;
    } 
   </style>
   
<script>
    export default {
        data: () => ({
                focus: '',
                type: 'month',
                typeToLabel: {
                    month: 'Месяц',
                    week: 'Неделя',
                    day: 'День',
                    '4day': '4 дня',
                },
                events: [],
                extendOriginal: null,
                loading: true,
                selectedEvent: {},
                selectedElement: null,
                selectedOpen: false,
            }),
            props:{
              userId:String  
            },
        components: {
            
        },
        created() {
            this.fetchData()
        },
        methods: {
            viewDay( { date }) {
                this.focus = date
                this.type = 'day'
            },
            setToday() {
                this.focus = ''
            },
            showEvent( { nativeEvent, event }) {
                const open = () => {
                    this.selectedEvent = event
                    this.selectedElement = nativeEvent.target
                    requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
                }

                if (this.selectedOpen) {
                    this.selectedOpen = false
                    requestAnimationFrame(() => requestAnimationFrame(() => open()))
                } else {
                    open()
                }

                nativeEvent.stopPropagation()
            },
            fetchData() {
                this.$http.get('calendar/user-events?id='+this.userId).then(response => {
                    var data = response.data
                    for (var index in data) {
                        var start = new Date(parseInt(data[index].startTime) * 1000);


                        var end = new Date(parseInt(data[index].endTime) * 1000);

                        var name=''
                        if(data[index].lesson && data[index].lesson.topic){
                             name=data[index].lesson.topic.name + ' - '
                        }
                        if(data[index].lesson) {
                            name=name+data[index].lesson.name
                        }
                        var color='black'
                        var type='none'
                        if(data[index].lesson)
                        {
                            if(data[index].lesson.type=='control'){color="red"}
                            if(data[index].lesson.type=='practice'){color="blue"}
                            if(data[index].lesson.type=='theory'){color="blue-grey"}
                            type=data[index].lesson.type
                        }
                        this.events.push({
                            id: data[index].id,
                            name: name,
                            color: color,
                            start: start.getTime(),
                            end: end.getTime(),
                            type: type

                        })
                    }

                    this.loading = false
                })
            },

    }
        
    }

</script>
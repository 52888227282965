<template>
    <v-card flat class="mb-7">
        <v-card-text class="pa-0">
            <div class="p-4  text-center">
                <div class="d-flex justify-center pt-5">
                                    <img v-if="user && user.avatar"
                     :src="user.avatar"
                     width="128px"
                     class="img-fluid rounded-circle elevation-1"
                     >
                <i v-else  width="128px" class=" fa fa-3x fa-user-circle"></i>
                </div>
                <h2 class="mb-0 mt-4 font-weight-regular">{{user.last_name}} {{user.name}} {{user.second_name}}</h2>
                <p class="font-weight-light">{{user.organization}}</p>
                <v-row class="mt-1 pb-1">
                    <v-col cols="6" v-if="user && user.rating">
                        <h2 class="mb-0 font-weight-light">{{user.rating}}</h2>
                        <v-rating
                            readonly
                            :value="user.rating"
                            background-color="purple lighten-3"
                            color="purple"
                            dense
                            half-increments
                            size="14"
                            ></v-rating>
                        <small class>Успеваемость</small>
                    </v-col>
                    <v-col cols="6" v-if="user && user.battleRating">
                        <h2 class="mb-0 font-weight-light">{{user.battleRating}}</h2>
                        <v-rating
                            readonly
                            :value="user.battleRating"
                            background-color="purple lighten-3"
                            color="purple"
                            dense
                            half-increments
                            size="16"
                            ></v-rating>
                        <small class>Рейтинг в битвах</small>
                    </v-col>
                </v-row>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        name: "TheProfileCard",
        data: () => ({}),
        props: {
            user: Object
        },
        methods: {

        },
        components: {}
    };
</script>
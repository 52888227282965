<template>
  <v-card flat class="mb-7">
    <v-card-text class="pa-0">
      <div class="info pa-4">
        <h3 class="white--text title font-weight-regular">Ближайшие занятия</h3>
        <v-btn id="comingBtn" color="success" @click="calendarModal=!calendarModal" fab dark class="mt-n5 elevation-0 mr-4" style="position: absolute;right: 5px; z-index:1;">
          <v-icon>mdi-calendar</v-icon>
        </v-btn>
      </div>

            <div class="vs-scrollable" v-bar  style="height:100px;   " ref="scrollWindow" >
                <v-list style='  padding-right: 14px;'>
                    <div v-for="(day, i) in coming.days" :key="i">
                        <p class="line mb-0" v-if="i==0 || (i>0 && day.month!=coming.days[i-1].month)"><span>{{day.month}}</span></p>
                    <v-list-item  class="px-1"  :inactive="day.lessons.length>0 ? false:true">
                        <v-list-item-title>
                            <div class="d-flex align-center py-3">
                                <div class="mx-3">
                                    <v-btn :disabled="!day.active"
                                           class="mx-2 elevation-0"
                                           fab
                                           height="45"
                                           width="45"
                                           :color="day.btncolor"
                                           >
                                        <v-icon dark class="body-2">{{day.day}}</v-icon>

                                    </v-btn> 
                                </div>
                                <div v-if="day.lessons.length>0" >
                                    <div v-for="lesson in day.lessons" :key="lesson.id">
                                        <div v-if="lesson.type==null">
                                        <v-tooltip right>
                                            <template v-slot:activator="{ on, attrs }">
                                                <div
                                                    color="primary"
                                                    dark
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    >
                                                    <h4 class="font-weight-regular"> {{ lesson.lesson.name }}</h4>
                                                    <span
                                                        class="text--secondary caption text-truncate descpart d-block truncate-text"
                                                        >{{lesson.lesson.topic.name}}</span>
                                                </div>
                                            </template>
                                            <span>{{lesson.lesson.topic.name}} - {{ lesson.lesson.name }}</span>
                                        </v-tooltip>
                                        <v-chip small  v-if="lesson.lesson.type=='theory'">Теоретическое занятие</v-chip>
                                        <v-chip small  color="primary"  v-if="lesson.lesson.type=='practice'">Практическое занятие</v-chip>
                                        <v-chip small color="warning"  v-if="lesson.lesson.type=='self'">Самостоятельная подготовка</v-chip>
                                        <v-chip  small  color="error"  v-if="lesson.lesson.type=='control'">Контроль</v-chip>
                                        <v-btn v-if="day.active" icon :to="'/lesson/'+lesson.id" color='primary' ><v-icon>mdi-arrow-right-circle</v-icon></v-btn>
                                        </div>
                                        <div v-else>
                                            <h3 class="font-weight-regular" v-if="lesson.type=='PTZ'">ПТЗ</h3>
                                            <h3 class="font-weight-regular" v-if="lesson.type=='PTU'">ПТУ</h3>
                                                                                                <span
                                                        class="text--secondary caption text-truncate descpart d-block truncate-text"
                                                        >{{lesson.mapObject.name}}</span>
                                            <v-chip small  color="primary" >{{lesson.mapObject.address}}</v-chip><br>
                                            <v-btn small dark color="blue" @click="timetableId=lesson.id; ptzModal=true" class="ma-1">Просмотр</v-btn>
                                        </div>
                                        <span
                                            class="text--secondary caption text-truncate descpart d-block truncate-text"
                                            ><span>{{ parseInt(lesson.startTime) | moment("h:mm a") }}</span>
                                            
                                        </span>

                                    </div>
                                </div>
                                <div v-else>

                                    <h4 class="font-weight-regular">{{ day.text }}</h4>
                                    <span
                                        class="text--secondary caption text-truncate descpart d-block truncate-text"
                                        >{{day.desc}}</span>
                                    <span
                                        class="text--secondary caption text-truncate descpart d-block truncate-text"
                                        >{{day.time}}</span>
                                </div>
                            </div>
                        </v-list-item-title>
                    </v-list-item>
                    </div>
                </v-list>
            </div>
        </v-card-text>
        <v-dialog v-model="calendarModal">
            <v-card>
                <v-card-text><timetable :userId="userId"/></v-card-text>
            </v-card>
        </v-dialog>
              <v-dialog v-model="ptzModal">
            <v-card>
                <v-card-text><ptz :timetableId="timetableId"/></v-card-text>
            </v-card>
        </v-dialog>
    </v-card>
</template>
       <style>
            .line {
                font-size: 16px;
                text-align: center;
                position: relative;
            }
            .line span {
                background-color: #ffffff;
                position: relative;
                z-index: 1;
                padding: 0px 25px;
            }
            .line:after {
                content: '';
                display: block;
                width: 100%;
                height: 1px;
                background-color: #262626;
                position: absolute;
                top: 50%;
                margin-top: -1px;
                z-index: 0;
            }
        </style>
<script>
    import ptz from './ptz'
    import timetable from './timetable'
    export default {
        name: "index",
        data: () => ({
                calendarModal: false,
                loading: true,
                coming: [],
                timetableId:null,
                ptzModal:false
            }),
            props:{
              userId:String
            },
        methods: {
            fetchData() {
                this.$http.get('calendar/coming?id='+this.userId).then(response => {
                    
                    this.coming = response.data

                    this.loading = false
                    this.$nextTick(() => {
                    this.$parent.matchHeight()
                })
                })

            },
            setHeight(val) {
                this.$refs.scrollWindow.style.height = val
            }
        },
        mounted() {
            this.fetchData()
        },
        components: {
            timetable,
            ptz
        }
    };
</script>
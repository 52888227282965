<template>
    <div id="proile-settings">
        <center>
        <h2 v-if="timetable.type=='PTZ'" class="pt-3">Пожарно-тактическое занятие</h2> 
        <h2 v-if="timetable.type=='PTU'" class="pt-3">Пожарно-тактическое учение</h2>
        </center>
        <v-row><v-col class="pt-7"><objectView  :editMod="false"  :stations="[]" :object="timetable.mapObject"/></v-col><v-col  class="pt-7" v-html="timetable.task"></v-col></v-row>
      
    </div>
</template>
<script>
    import objectView from '../serviceArea/objectView' 
    
    export default {
        name: 'ptz',
        components: {
           objectView
        },
        data() {
            return {
                timetable:Object
            }
        },
        props:{
          timetableId:String 
        },
        watch:{
            timetableId(){
                this.fetchData();
            }
        },
        methods: {
            fetchData() {
                this.$http.get('calendar/ptz-get', {
                    params: {
                        id: this.timetableId
                    }
                }).then(response => {
                    // JSON responses are automatically parsed.
                    this.timetable = response.data
                })
            }
        },

        mounted() {
            this.fetchData()
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>

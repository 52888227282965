<template>
    <div>
        <helper :groupId="1" :autostart="true" delay='6000' :closeOnEnd='true'/>
        <v-row>
            <v-col cols="12" sm="12" lg="4">
                <div ref='profile' id='profile'><TheProfileCard :user="user" ></TheProfileCard></div>
                <coming  ref="coming" v-if="userId>0" :userId="userId" id='coming'/>
            </v-col>
            <v-col cols="12" sm="12" lg="8">
                <TheActivityTimeline v-if="user && user.user_id" ref='activity' id='activity' :userId="userId"></TheActivityTimeline>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import coming from './coming'
    import helper from '@/views/helper'
    import TheProfileCard from './TheProfileCard'
    import TheActivityTimeline from './TheActivityTimeline'
    export default {
        name: "Analytical",

        data: () => ({
            user:{},
            loading:false,
            userId:0
            }),
        methods: {
            matchHeight() {
                if (this.$refs.profile) {
                    var activityHeight = document.documentElement.clientHeight - 170 + 'px'
                    this.$refs.activity.setHeight(activityHeight)
                    var comingHeight = document.documentElement.clientHeight - 170 - this.$refs.profile.clientHeight + 'px'
                    this.$refs.coming.setHeight(comingHeight)
                    
                }
            },
            fetchData() {
                this.userId=this.$auth.user().profile.user_id
                if (this.$route.params.id) {
                    this.userId = this.$route.params.id
                }
                this.$http.get('profile/get?id=' + this.userId).then(response => {
                    this.user = response.data
                    this.loading = false
                    this.$nextTick(()=>{
                        this.matchHeight()
                    })
                })

            }
        },
        beforeDestroy: function () {
            window.removeEventListener('resize', () => this.matchHeight())
        },
        mounted() {
            this.fetchData()
            window.addEventListener('resize', () => this.matchHeight())
            
        },
        components: {
            coming,
            TheProfileCard,
            TheActivityTimeline,
            helper
            
        }
    };

</script>
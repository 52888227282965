<template>
    <div>
                <v-timeline dense style="heigth:100%">

                    <v-timeline-item class="align-start" large v-for="(activity,index) in activities" :key="index">
                        <template v-slot:icon>
                            <v-avatar :color='getColor(activity.action)'>
                                <v-icon  dark>{{getIcon(activity.action)}}</v-icon>
                            </v-avatar>
                        </template>
                        <v-card class="elevation-0">
                            <v-card-title class="subtitle-1 pa-0">
                                {{ parseInt(activity.date) | moment("from", "now") }}
                            </v-card-title>

                            <div class="subtitle-2 font-weight-regular mb-3">
                                {{activity.text}}
                            </div>
                            <v-btn depressed v-if="isLinked(activity.action)" color="success" :to="getLink(activity)">{{getLinkText(activity)}}</v-btn>


                            <v-divider class="my-4"></v-divider>
                        </v-card>
                    </v-timeline-item>
                </v-timeline>
        </div>
</template>

<script>
    export default {
        name: "index",
        data: () => ({
                loading: true,
                activities: [],

            }),
        methods: {
            getColor(action) {
                  switch (action) {
                    case 'lessonRemove':
                        return 'error'
                                            case 'lessonAdd':
                        return 'green'
                    default:
                        return null;
                }
            },
            getIcon(action) {
                switch (action) {
                    case 'lessonAdd':
                        return 'mdi-plus-thick'
                      case 'lessonRename':
                        return 'mdi-lead-pencil'                      
                            case 'lessonRemove':
                        return 'mdi-delete'
                    default:
                        return null;
                }

            },
            getLinkText(activity) {
                switch (activity.action) {
                    case 'lessonAdd':
                    case 'lessonRename':                  
                        return 'Посмотреть занятие'
                    default:
                        return null;
                }
            },
            getLink(activity) {
                switch (activity.action) {
                    case 'lessonAdd':
                    case 'lessonRename':                  
                        return '/lesson/'+activity.targetId
                    default:
                        return null
                }
            },
            isLinked(action) {
                switch (action) {
                    case 'lessonAdd':
                    case 'lessonRename':                  
                        return true
                    default:
                        return false
                }
            },
            fetchData() {
                                var userId=this.$auth.user().profile.user_id
                if (this.$route.params.id) {
                    userId = this.$route.params.id
                }
                this.$http.get('logs/get?id='+userId).then(response => {
                    this.activities = response.data
                    this.loading = false
                    this.$parent.$parent.$parent.$parent.$parent.setHeight(0)
                })

            }
        },
        mounted() {
            this.fetchData()
        },
        components: {
        }
    };
</script>